import styled from 'styled-components'


const Container__Box = styled.div`
  padding: 0px 50px 0px 50px;
  position: relative;
  justify-content: center;
  @media (max-width: 1024px) {
    display: flex;
  gap: 16px;
  flex-direction: column;
  }
`

const Container__Title = styled.div`
display: flex;
align-items: center;
  h2 {
    color: #DA1A00;
    font-weight: 700;
    font-size: 32px;
    line-height: 52px;
  h4 {
    padding-bottom:16px;
    text-decoration: underline 3px solid #fbb22f;
  }
    span {
      text-decoration: underline 3px solid #fbb22f;
    }
    @media (max-width: 768px) {
      font-size: 32px;
    }
    @media (max-width: 480px) {
      font-size: 22px;
    }
  }
`

const Container__TitleAccountant = styled.div`
display: flex;
align-items: center;
  h2 {
    color: #DA1A00;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: ${(props) => props.theme.colors.white};
  p {
    text-decoration: underline 3px solid #fbb22f;
    font-size: 24px;
    color: ${(props) => props.theme.colors.white};
  }
    span {
      text-decoration: underline 3px solid #fbb22f;
    }
  }
`

const Container__Content = styled.div`
  padding: 16px 24px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  p {
    color: #6B7786;
    padding-top: 8px;
    line-height: 20px;
    font-size: 12px;
  }
`
const Container__Accountant = styled.div`
display: flex;
align-items: center;
width: 100%;
justify-content: space-between;
@media (max-width: 600px) {
  flex-direction: column;
  gap: 16px;
}
`
const Container__ContentAccountant = styled.div`
  position: absolute;
  width: 30%;
  background: ${(props) => props.theme.colors.primary};
  padding: 16px 24px;
  box-shadow: 0px 2px 10px rgba(73, 80, 87, 0.16);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -65px;
  right: 50px;
  @media (max-width: 1024px) {
    position: initial;
    width: 100%;
  }

`
const Container__Button = styled.button`
  margin-top: 6px;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  padding: 4px 28px;
  border: none;
  background: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  transition: all 300ms linear 0s;
  &:hover {
    background: ${(props) => props.theme.colors.lightgray};
    color: ${(props) => props.theme.colors.primary};
  }
`

export const Container = {
  box: Container__Box,
  content: Container__Content,
  title: Container__Title,
  accountant: Container__Accountant,
  button: Container__Button,
  contentAccountant: Container__ContentAccountant,
  titleAccountant: Container__TitleAccountant
}

