import { Container } from './styles'
import { useEffect, useState } from 'react';
import { axiosClient } from '../../config/axios';

export const Accountant = () => {
  const [collect, setCollect] = useState(0);
  const [voluntary, setVolutary] = useState(0);
  const [city, setCity] = useState(0);

  const formatCurrency = (value: number) => {
    const formattedValue = (value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    return formattedValue;
  };

  useEffect(() => {
    axiosClient
      .get('/campaigns/orgs/9/counter')
      .then((response) => {
        setCollect(response.data.data.donationSum);
        setVolutary(response.data.data.volunteersCount);
        setCity(response.data.data.citiesCount);
      })
      .catch((error) => console.log(error))
  }, [])

  useEffect(() => {
    animateCount(collect, setCollect);
    animateCount(voluntary, setVolutary);
    animateCount(city, setCity);
  }, []);

  const animateCount = (targetValue: number, setValue: React.Dispatch<React.SetStateAction<number>>) => {
    let startValue = 0;
    const increment = Math.ceil(targetValue / 100);

    const animate = () => {
      if (startValue + increment < targetValue) {
        setValue(prevValue => prevValue + increment);
        startValue += increment;
        setTimeout(animate, 10);
      } else {
        setValue(targetValue);
      }
    };
    animate();
  };
  return (
    <Container.box>
      <Container.contentAccountant >
        <Container.accountant id='institutional'>
          <Container.titleAccountant>
            <h2>
              <p> {formatCurrency(collect)}</p>
              Arrecadado
            </h2>
          </Container.titleAccountant>
          <Container.titleAccountant>
            <h2>
              <p>{voluntary}</p> Voluntários
            </h2>
          </Container.titleAccountant>
          <Container.titleAccountant>
            <h2>
              <p>{city} </p> Municípios
            </h2>
          </Container.titleAccountant>
        </Container.accountant>
        <Container.button>
          <a href="/#causas"> Quero participar</a>
        </Container.button>
      </Container.contentAccountant>

    </Container.box>
  )
}
